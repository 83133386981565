import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Table, Button, message, Spin, Tabs } from 'antd';
import { CopyOutlined, FileOutlined, SyncOutlined } from '@ant-design/icons';
import { DataCrafterTemplateFormStore } from '../../stores';
import { OutputPackageSelector } from '..';
import { DataCrafterTemplateColumn } from '../../types';
import MappingRuleInput from './MappingRuleInput';
import './OutputPackageColumnsSelector.less';

type Props = {
    createStore: DataCrafterTemplateFormStore;
};

const OutputPackageColumnsSelector: React.FC<Props> = ({ createStore }) => {
    const handleCopy = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
            message.success('Column name copied to clipboard');
        });
    };

    const handleFetchColumns = () => {
        if (!createStore.selectedOutputPackage) {
            message.warning('Please select an output package');
            return;
        }
        createStore.fetchOutputColumns();
    };

    const tableColumns = [
        {
            title: 'Column',
            dataIndex: 'name',
            key: 'name',
            width: 'auto',
            render: (_: string, record: DataCrafterTemplateColumn) => {
                return (
                    <div className="column-name-cell">
                        <span>{record.name}</span>
                        <Button
                            type="text"
                            icon={<CopyOutlined />}
                            onClick={() =>
                                handleCopy(`${record.worksheetName}:${record.name}:${record.headerCoordinates}`)
                            }
                            size="small"
                        />
                    </div>
                );
            }
        },
        {
            title: 'Address',
            dataIndex: 'headerCoordinates',
            key: 'headerCoordinates',
            width: 100,
            render: (headerCoordinates: string) => {
                return <span>{headerCoordinates}</span>;
            }
        },
        {
            title: 'Mapping Rule',
            dataIndex: 'mappingRule',
            key: 'mappingRule',
            render: (_: string, record: DataCrafterTemplateColumn) => (
                <MappingRuleInput createStore={createStore} templateColumn={record} />
            )
        }
    ];

    // Group columns by worksheet
    const tabPanes = React.useMemo(() => {
        const worksheets = new Map<string, DataCrafterTemplateColumn[]>();
        createStore.outputColumns.forEach(col => {
            if (!worksheets.has(col.worksheetName)) {
                worksheets.set(col.worksheetName, []);
            }
            worksheets.get(col.worksheetName)?.push(col);
        });

        return Array.from(worksheets.entries()).map(([worksheet, columns]) => (
            <Tabs.TabPane
                key={worksheet}
                tab={
                    <>
                        <FileOutlined />
                        {worksheet}
                    </>
                }
            >
                <Table
                    className="columns-table"
                    dataSource={columns}
                    columns={tableColumns}
                    pagination={false}
                    size="middle"
                    bordered={false}
                    scroll={{ y: 300 }}
                    rowKey={record => `${record.worksheetName}-${record.name}-${record.headerCoordinates}`}
                />
            </Tabs.TabPane>
        ));
    }, [createStore.outputColumns]);

    return (
        <div className="output-package-columns-selector">
            <div className="package-selector-section">
                <OutputPackageSelector
                    packages={createStore.packages}
                    isLoadingPackages={createStore.isLoadingPackages}
                    value={createStore.selectedOutputPackage}
                    onChange={value => createStore.setSelectedOutputPackage(value)}
                    placeholder="Select output package"
                />
                <Button
                    type="primary"
                    icon={<SyncOutlined spin={createStore.isLoadingOutputColumns} />}
                    onClick={handleFetchColumns}
                    loading={createStore.isLoadingOutputColumns}
                    disabled={!createStore.selectedOutputPackage}
                >
                    Fetch Columns
                </Button>
            </div>
            <Spin spinning={createStore.isLoadingOutputColumns}>{tabPanes.length > 0 && <Tabs>{tabPanes}</Tabs>}</Spin>
        </div>
    );
};

export default observer(OutputPackageColumnsSelector);
