import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Layout, Button, Collapse, Form, Input, Spin, Row, Col } from 'antd';
import LayoutHeader from '../../../../components/LayoutHeader';
import { Content } from 'antd/lib/layout/layout';
import { Utils } from '../../../common/services/Utils';
import { DataCrafterTemplatesVisualStore, DataCrafterTemplateFormStore } from '../../stores';
import './EditDataCrafterTemplate.less';
import InputPackageColumnsSelector from '../InputPackageColumnsSelector';
import OutputPackageColumnsSelector from '../OutputPackageColumnsSelector';
import { DataCrafterTemplateScript } from '../index';
import { CodeOutlined, LoginOutlined, LogoutOutlined } from '@ant-design/icons';
import MLModelSelector from '../MLModelSelector';

type Props = {
    store: DataCrafterTemplatesVisualStore;
    formStore: DataCrafterTemplateFormStore;
};

const EditDataCrafterTemplate: React.FC<Props> = ({ store, formStore }) => {
    const [form] = Form.useForm();

    React.useEffect(() => {
        return () => {
            formStore.reset();
        };
    }, [formStore]);

    React.useEffect(() => {
        if (store.currentProject && store.selectedTemplateId) {
            formStore.loadPackages();
            formStore.initializeWithTemplate(store.selectedTemplateId);
        }
    }, [store, store.currentProject, store.selectedTemplateId, formStore]);

    // Update form values when template is loaded
    React.useEffect(() => {
        if (formStore.selectedTemplate) {
            form.setFieldsValue({
                templateName: formStore.templateName,
                plannerModelId: formStore.selectedPlannerModelId,
                coderModelId: formStore.selectedCoderModelId
            });
        }
    }, [
        form,
        formStore.selectedTemplate,
        formStore.templateName,
        formStore.selectedPlannerModelId,
        formStore.selectedCoderModelId
    ]);

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            await formStore.updateTemplate(values.templateName, values.plannerModelId, values.coderModelId);
        } catch (error) {
            // Form validation failed
        }
    };

    // Handle form field changes with a single handler
    const handleFormValuesChange = (changedValues: any, allValues: any) => {
        if ('templateName' in changedValues) {
            formStore.setTemplateName(changedValues.templateName);
        }
        if ('plannerModelId' in changedValues) {
            formStore.setSelectedPlannerModelId(changedValues.plannerModelId);
        }
        if ('coderModelId' in changedValues) {
            formStore.setSelectedCoderModelId(changedValues.coderModelId);
        }
    };

    return (
        <Layout className="edit-data-crafter-template" style={{ height: '100%', background: 'white' }}>
            <LayoutHeader
                subtitle={Utils.getSubtitle(store.currentProject)}
                helpMessage=""
                title="Edit Data Crafter Template"
                buttons={[
                    <Button
                        key="data-crafter-templates-go-back"
                        data-id="button-go-to-list"
                        className="light"
                        size="large"
                        onClick={() => formStore.navigateToList()}
                    >
                        Go back
                    </Button>,
                    <Button
                        key="data-crafter-templates-submit"
                        data-id="button-submit"
                        type="primary"
                        size="large"
                        onClick={handleSubmit}
                        disabled={!formStore.canSubmit || formStore.isLoadingTemplate}
                    >
                        Update Template
                    </Button>
                ]}
            />
            <Layout>
                <Content style={{ maxHeight: 'calc(100vh - 70px)', overflowY: 'auto' }}>
                    <div style={{ padding: '24px' }}>
                        <Spin spinning={formStore.isLoadingTemplate} tip="Loading template...">
                            <Form
                                form={form}
                                layout="vertical"
                                className="data-crafter-template-form"
                                onValuesChange={handleFormValuesChange}
                            >
                                <Form.Item
                                    name="templateName"
                                    label="Template Name"
                                    rules={[
                                        { required: true, message: 'Please enter a template name' },
                                        { whitespace: true, message: 'Template name cannot be empty' }
                                    ]}
                                >
                                    <Input placeholder="Enter template name" disabled={formStore.isLoadingTemplate} />
                                </Form.Item>

                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name="plannerModelId"
                                            label="Agent Model"
                                            rules={[{ required: true, message: 'Please select a agent model' }]}
                                        >
                                            <MLModelSelector
                                                models={formStore.mlModels}
                                                isLoading={formStore.isLoadingMlModels || formStore.isLoadingTemplate}
                                                placeholder="Select a agent model"
                                                disabled={formStore.isLoadingTemplate}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="coderModelId"
                                            label="Code Generation Model"
                                            rules={[
                                                { required: true, message: 'Please select a code generation model' }
                                            ]}
                                        >
                                            <MLModelSelector
                                                models={formStore.mlModels}
                                                isLoading={formStore.isLoadingMlModels || formStore.isLoadingTemplate}
                                                placeholder="Select a code generation model"
                                                disabled={formStore.isLoadingTemplate}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Collapse defaultActiveKey={['1', '2', '3']} bordered={false}>
                                    <Collapse.Panel
                                        header={
                                            <span>
                                                <LoginOutlined /> Step 1: Select Input Package Columns
                                            </span>
                                        }
                                        key="1"
                                        collapsible={formStore.isLoadingTemplate ? 'disabled' : undefined}
                                    >
                                        <InputPackageColumnsSelector createStore={formStore} />
                                    </Collapse.Panel>
                                    <Collapse.Panel
                                        header={
                                            <span>
                                                <LogoutOutlined /> Step 2: Configure Output Package Columns
                                            </span>
                                        }
                                        key="2"
                                        collapsible={formStore.isLoadingTemplate ? 'disabled' : undefined}
                                    >
                                        <OutputPackageColumnsSelector createStore={formStore} />
                                    </Collapse.Panel>
                                    <Collapse.Panel
                                        header={
                                            <span>
                                                <CodeOutlined /> Step 3: Compile Template Script
                                            </span>
                                        }
                                        key="3"
                                        collapsible={formStore.isLoadingTemplate ? 'disabled' : undefined}
                                    >
                                        <DataCrafterTemplateScript store={formStore} />
                                    </Collapse.Panel>
                                </Collapse>
                            </Form>
                        </Spin>
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};

export default observer(EditDataCrafterTemplate);
